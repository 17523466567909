import React, { Component } from 'react';
import Starters from './starters';
import {MENU_MAP} from "./menu-map";
import './menu.css';
import Pizza from "./pizza";
import Pasta from "./pasta";
import Entrees from "./entrees"
import KidsMeals from "./kids-meals";
import Sandwiches from "./sandwiches";
import Button from "react-bootstrap/Button";

export default class Menu extends Component {

    render () {
        return (
            <div className="container">
                <div className="menu-section"><i>STARTERS</i></div>
                    <Starters items={MENU_MAP.get("starters")}/>
                <div className="menu-section"><i>PIZZA</i></div>
                    <div  className="menu-section-desc"><i>All pizzas come with homemade sauce and special blended cheese. Also, a choice of thin, regular, or Sicilian Deep Dish.</i></div>
                    <Pizza pizzaItems={MENU_MAP.get("pizzaItems")} pizzaCrusts={MENU_MAP.get("pizzaCrusts")} pizzaTypes={MENU_MAP.get("pizzaTypes")}/>
                <div className="menu-section"><i>PASTA</i></div>
                <div  className="menu-section-desc"><i>All of Capozio's sauces are made from scratch. These recipes were brought to America in 1914 from Sicily and have remained untouched as they were handed down to each generation.</i></div>
                <Pasta pastaTypes={MENU_MAP.get("pastaTypes")} pastaSauces={MENU_MAP.get("pastaSauces")} />
                <br/>
                <div className="menu-section"><i>ENTREES</i></div>
                    <Entrees items={MENU_MAP.get("entrees")}/>
                <div className="menu-section"><i>KID'S MEALS</i></div>
                    <KidsMeals items={MENU_MAP.get("kidsMeals")} />
                <div className="menu-section">SANDWICHES</div>
                    <div  className="menu-section-desc"><i>Capozio's homemade bread baked daily <b>•</b> All sandwiches served with Potato Chips</i></div>
                    <Sandwiches items={MENU_MAP.get("sandwiches")} />
                <Button variant="success" className="btn-circle phone-button" href="tel:2694694001"><i className="fas fa-phone"></i></Button>
            </div>
        )
    };
}

import React from 'react';


// Documentation on Google Maps
// https://developers.google.com/maps/documentation/embed/guide#place_id_parameters

// Place ID: ChIJP9nVZx8eEYgRd5IVRSjUE9k

const Directions = props => {
    let placeID = "ChIJP9nVZx8eEYgRd5IVRSjUE9k"
    let googleURL = `https://www.google.com/maps/embed/v1/place?key=AIzaSyClQnuxlDhxgWcbth2WN_ns_W45B0TeKpg&q=place_id:${placeID}`
return (
    <div className="container text-center" id="directions">
        <h1>DIRECTIONS</h1>
        <div id="map-container-google-2" className="z-depth-1-half map-container" style={{height: "500px"}}>
        <iframe src={googleURL} 
        frameBorder="0" style={{border: "0", width: "100%", height: "100%"}} title="Google Maps" allowFullScreen></iframe>
        </div>
    </div>
)
};

export default Directions;


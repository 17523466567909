import React from 'react'
import {Row} from "react-bootstrap";
import {CateringItem} from "../../models/CateringItem";

export default function CateringItalianDishes(props: any) {
    return <>
        { props.items.map((item:CateringItem, index:number) =>
            <Row>
                <div key={index}>
                    <p className="catering-item"><b>{item.name}</b></p>
                    <p className="catering-desc">{item.desc}</p>
                    { item.halfPrice < 0 || item.fullPrice < 0 ?
                        <p className="catering-price"><i>Market Price</i></p>
                        : <p className="catering-price"><i>{item.halfPrice} / {item.fullPrice}</i></p> }
                </div>
            </Row>
        )}
    </>
}

const defaultUser = {
    username: "unknown",
    email: "unknown",
    phone_number: "unknown",
    first_name: "unknown",
    last_name: "unknown",
    image_url: "https://s3.amazonaws.com/ignaciosbucket/IMG_1754.png"
}


export default function authReducer(state = {loggedIn: false, 
                                    user: defaultUser, 
                                    errorMessage: false,
                                    signUpErrorMessages: false
                                    }, action) {
    switch (action.type) {
    // Validating Tokens
        case "VALIDATING_TOKEN":
            return state
        case "NO_TOKEN":
            return state
    // Log In 
        case "LOGGING_IN":
            return state
        case "LOGIN_RESPONSE":
            if (!action.payload.message){
                localStorage.setItem("token", action.payload.jwt);
                return {...state, loggedIn: true, user: action.payload.user, jwt: action.payload.jwt}
            } else {
                return {...state, loggedIn: false, errorMessage: action.payload}
            }
    // Log Out
        case "LOG_OUT":
            return {...state, loggedIn: false, jwt: '', user: defaultUser}
        case "PROFILE_RESPONSE":
            if(!action.payload.error) {
                return {...state, loggedIn: true, user: {...defaultUser, ...action.payload.user}};
            } else {
                console.warn(action.payload);
                localStorage.removeItem("token");
                return state;
            }
        case "TOKEN_RESET":
            console.warn(action.payload);
            localStorage.removeItem("token");
            return state;

    // Update Photo
            case "SENDING_PHOTO":
                return state;
            case "PHOTO_RESPONSE":
                return state
    // Create User
        case "CREATING_USER":
            return state;
        case "CREATE_USER_RESPONSE":
            if (action.payload.user) {
                localStorage.setItem("token", action.payload.jwt)
                return {
                    ...state, 
                    loggedIn: true, 
                    user: action.payload.user, 
                    jwt: action.payload.jwt
                }
            } else if (action.payload.messages){
            return {...state, signUpErrorMessages: action.payload.messages};
            } else {
                return state
            }
        default:
            return state;
    }
}
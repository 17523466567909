import React from 'react';
import { MenuItem } from "../../models/MenuItem";

export default function Sandwiches(props:any) {
    return <>
        <div className="sandwiches-row">
            <div className="sandwiches-column">
                {props.items.map((item:MenuItem, index:number) =>
                    index < (props.items.length / 2) ?
                        <div key={index}>
                            <p className="menu-p menu-item sandwiches-menu-item"><b>{item.name}</b></p>
                            <p className="menu-p menu-desc sandwiches-menu-desc sandwiches-full">{item.desc} <i className="menu-price">${item.price}</i></p>
                            <p className="menu-p menu-desc sandwiches-menu-desc sandwiches-mobile">{item.desc}</p>
                            <p className="menu-p menu-desc sandwiches-menu-desc sandwiches-mobile"><i className="menu-price">${item.price}</i></p>
                        </div>

                        : null
                )}
            </div>
            <div className="sandwiches-column">
                {props.items.map((item:MenuItem, index:number) =>
                    index >= (props.items.length / 2) ?
                        <div key={index}>
                            <p className="menu-p menu-item sandwiches-menu-item"><b>{item.name}</b></p>
                            <p className="menu-p menu-desc sandwiches-menu-desc sandwiches-full">{item.desc} <i className="menu-price">${item.price}</i></p>
                            <p className="menu-p menu-desc sandwiches-menu-desc sandwiches-mobile">{item.desc}</p>
                            <p className="menu-p menu-desc sandwiches-menu-desc sandwiches-mobile"><i className="menu-price">${item.price}</i></p>
                        </div>

                        : null
                )}
            </div>
        </div>
    </>
}
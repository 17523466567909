const defaults = {
    userOrders: undefined,
    subtotal: 0,
    tax: 0,
    total: 0
}

export default function orderReducer (state = defaults, action) {
    switch (action.type) {
        case "SENDING_ORDER":
            return state;
        case "ORDER_RESPONSE":
            return {...state, currentOrder: []};
        case "ADDING_ITEM":
            return state;
        case "ITEM_ADDED":
            return {...state, currentOrder: action.payload}
        case "RETRIEVE_ORDER":
            return {...state, currentOrder: action.payload}
    // Updating Order
        case "UPDATE_ORDER_QUANTITY":
            return {...state, currentOrder: action.payload}
        case "DELETE_ITEM":
            return {...state, currentOrder: action.payload}
    // Cancel Order
        case "CANCEL_ORDER":
            let updatedUserOrders = state.userOrders.map(order => {
                if (order.id === action.payload.id) {
                    order.status = "Canceled";
                    return order;
                } else {
                    return order;
                }
            })
            return {...state, userOrders: updatedUserOrders}
    // Prices
        case "UPDATE_TOTALS":
            return {...state, ...action.payload}
        case "USER_ORDERS_FETCHED":
            if(!action.payload.error){
                return {...state, userOrders: action.payload}
            } else {
                return state
            }
        case "RESET_USER_ORDERS":
            return {...state, userOrders: undefined}
        case "RESET_PRICE":
            return {...state, subtotal: 0, tax: 0, total: 0}
        default:
            return state
    }
}
import { combineReducers } from 'redux';

// Reducers
import authReducer from './authReducer';
import itemsReducer from './itemsReducer';
import orderReducer from './orderReducer';
const rootReducer = combineReducers({
    auth: authReducer,
    items: itemsReducer,
    order: orderReducer
});

export default rootReducer;
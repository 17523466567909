import React, { Component } from 'react';
import { connect } from 'react-redux';

import Contact from './Contact';
import Directions from './Directions';
import Services from './Services';
import HomePageCarousel from './Carousel';
import Hours from './Hours';
import Reservation from './Reservation';
import PhoneNumber from './PhoneNumber';
import AlertModal from "../alert/AlertModal";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            innerWidth: window.innerWidth,
            showModal: true,
            showBanner: true
        }
    }

    componentDidMount() {
    }

    onHide = event => {
        this.setState({showModal: false});
    }

    onShowModal = event => {
        this.setState({showModal: true});
    }

    render () {
        return (
            <section className="app-container">
                     {/*<AlertBanner show={this.state.showBanner} onShowModal={this.onShowModal} />*/}
                     {/*<AlertHiring show={this.state.showBanner} onShowModal={this.onShowModal} /> */}
                    {/*<AlertBannerOnlineOrdering show={this.state.showBanner} onShowModal={this.onShowModal} />*/}
                    {/* <AlertModal show={this.state.showModal} onHide={this.onHide} />*/}
                    <HomePageCarousel />
                    <br />
                    <PhoneNumber />
                    { this.state.innerWidth > 768 ?
                        <div className="container" style={{maxWidth: '1000px'}}>
                            <div className="row">
                                <div id="hours" className="col">
                                    <Hours />
                                </div>
                                <div id="reservations" className="col">
                                    <Reservation />
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <br />
                                <Hours />
                            <br />
                            <div style={{backgroundColor: '#f7f7f9', borderRadius: '25px', padding: '15px'}}>
                                <Reservation />
                            </div>
                        </div>
                    } 
                    <br />
                    <Services />
                    <Directions />
                    <br />
                    <Contact />
            </section>
        )
    }
}

export default connect()(HomePage);

let base = "https://api.capozios.com"
// let base = "http://localhost:8080";

const constants = {
    postReservationsUrl : `${base}/reservations`,
    getAvailableDatesUrl : `${base}/availability/`,
    authorization: "Basic d2ViYXBwOnd1cWV4eTdZVEc0enF2d3Vh"
}

export default constants;

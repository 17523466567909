import React, { Component } from "react";
import Logo from '../imgs/capozio_logo.png';
import { logOut } from "../actions/login";
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar'
import './Navbar.css'
import {Nav} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

class NavBar extends Component {

render () {
    return <Navbar className="navbar-container" expand="md" collapseOnSelect>
                <Navbar.Brand onClick={() => <Navigate to='/' />}>
                    <LinkContainer to="/"><img src={Logo} className="logo-height" alt="Capozio's Logo" onClick={() => <Navigate to='/' />} /></LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Item>
                            <Nav.Link href="https://capozios.square.site">Order Online</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://squareup.com/gift/JG62SV8VNHHS8/order">Gift Cards</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/menu">
                                <Nav.Link className="nav-link navbar-text">Menu</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/catering">
                                <Nav.Link className="nav-link navbar-text">Catering</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/jobs">
                                <Nav.Link className="nav-link navbar-text">Jobs</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
    }
}

const mapStateToProps = (state:any )=> {
    return {
        loggedIn: state.auth.loggedIn
    }
};

const mapDispatchToProps = (dispatch:any) => {
    return {
        logOut: () => dispatch(logOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

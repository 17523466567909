import React, {Component} from 'react';
import CateringItalianDishes from "./catering-italian-dishes";
import { CATERING_MAP } from "./catering-menu";
import { Container } from "react-bootstrap";
import './catering.css'


export default class CateringMenu extends Component {
    render() {
        return (
            <Container style={{maxWidth: '900px'}}>
                <div className="menu-section"><i>CATERING MENU</i></div>
                <div  className="catering-desc"><i>All pasta pan prices include salad, 2 slices of bread per person, butter, parmesan cheese and dressing.</i></div>
                <div  className="catering-desc"><i>Half pan feeds 10 -15 people and full pan feeds 20 – 25 people</i></div>
                <CateringItalianDishes items={CATERING_MAP.get("italianDishes")} />
            </Container>
        )
    }
}

import {CateringItem} from "../../models/CateringItem";

const ITALIAN_DISHES:CateringItem[] = [
    {name: "Mostaccioli", desc: "", halfPrice: 80, fullPrice: 110},
    {name: "Baked Mostaccioli", desc: "", halfPrice: 85, fullPrice: 125},
    {name: "Tortellini", desc: "", halfPrice: 90, fullPrice: 125},
    {name: "Tortellini Prosciutto Alfredo", desc: "", halfPrice: 120, fullPrice: 200},
    {name: "Manicotti / Cannelloni", desc: "Approximately 24 shells for a full and 12 for a half", halfPrice: 85, fullPrice: 160},
    {name: "Lasagna", desc: "", halfPrice: 85, fullPrice: 160},
    {name: "Spinach Lasagna", desc: "", halfPrice: 85, fullPrice: 160},
    {name: "Pollo Parmesan", desc: "Approximately 22 breasts for a full and 11 for a half. Comes with side of mostaccioli pasta", halfPrice: -1, fullPrice: -1},
    {name: "Eggplant", desc: "", halfPrice: 85, fullPrice: 160},
    {name: "Beef", desc: "Approximately 4 pounds for half and 8 pounds for a full. Comes with bread, green peppers and beef juice.", halfPrice: -1, fullPrice: -1},
]





export const CATERING_MAP = new Map<string, object>()
CATERING_MAP.set("italianDishes", ITALIAN_DISHES)

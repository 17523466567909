import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Modal } from "react-bootstrap";
import { ReservationService } from "../../services/ReservationService";

export default class Reservation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toJSON().slice(0,10),
            firstName: "",
            lastName: "",
            partySize: "",
            phoneNumber: "",
            reservationTime: "",
            availableTimes: [],
            availableSizes: [],
            availabilityList: [],
            submitSuccess: false,
            submitError: false,
            showModal: false
        }
        this.reservationService = new ReservationService();
        this.handleDateOnChange = this.handleDateOnChange.bind(this)
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
    }

    async componentDidMount() {
        console.info("[INFO] state date", this.state.date)
        let response = await this.reservationService.findAvailableTimes(this.state.date, this.state.partySize)
        console.info("[INFO] Response.componentDidMount", response)
        this.setState(response)
    }

    showReservationSubmitSuccess = () => {
        this.setState({submitSuccess: true});
    }

    showReservationSubmitFailure = () => {
        this.setState({submitError: true});
    }

    showWehaveNoReservations = () => {
        // TODO: Tell the user that unfortunately we have no available reservations at this moment
        // TODO: Tell user that we like to reserve a portion of tables for walk-ins
    }

    handleShowModal = event => {
        this.setState({showModal: true})
    }

    async handleOnSubmit (event) {
        this.disableFields();
        if(this.validate()) {
            let stringDate = `${this.state.date} ${parseInt(this.state.reservationTime.split(":")[0]) + 12}:${this.state.reservationTime.split(":")[1]}`;
            console.log(this.state.reservationTime.slice(3,2));

            let postBody = {
                "firstName" : this.state.firstName,
                "lastName" : this.state.lastName,
                "phoneNumber" : this.state.phoneNumber.replace(/[^0-9]/g, ''),
                "reservationTime" : stringDate,
                "reservationSize" : parseInt(this.state.partySize)
                }

            console.info("[INFO] Posting reservation")

            let response = await this.reservationService.postReservation(postBody)

            if (response) {
                this.showReservationSubmitSuccess();
                this.enableFields();
            } else {
                this.showReservationSubmitFailure();
            }

        } else {
            this.enableFields();
        }
    }

    validate = () => {
        let isValid = true;

        if(this.state.firstName.length === 0) {
            document.getElementById("firstName").classList.add("is-invalid");
            isValid = false;
        } else {
            document.getElementById("firstName").classList.remove("is-invalid");
            document.getElementById("firstName").classList.add("is-valid");
        }
        

        if(this.state.lastName.length === 0) {
            document.getElementById("lastName").classList.add("is-invalid");
            isValid = false;
        } else {
            document.getElementById("lastName").classList.remove("is-invalid");
            document.getElementById("lastName").classList.add("is-valid");
        }
        

        if(this.state.phoneNumber.replace(/[^0-9]+/g, '').length !== 11 ) {
            document.getElementById("phoneNumber").classList.add("is-invalid")
            isValid =false;
        } else {
            document.getElementById("phoneNumber").classList.remove("is-invalid")
            document.getElementById("phoneNumber").classList.add("is-valid")
        }

        return isValid;

    }

    // TODO: Refactor, could combine these two methods together
    disableFields = () => {
        document.getElementById("firstName").readOnly = true;
        document.getElementById("lastName").readOnly = true;
        document.getElementById("date").disabled = true;
        document.getElementById("partySize").disabled = true;
        document.getElementById("reservationTime").disabled = true;
        document.getElementById("phoneNumber").readOnly = true;
    }

    enableFields = () => {
        document.getElementById("firstName").readOnly = false;
        document.getElementById("lastName").readOnly = false;
        document.getElementById("date").disabled = false;
        document.getElementById("partySize").disabled = false;
        document.getElementById("reservationTime").disabled = false;
        document.getElementById("phoneNumber").readOnly = false;
    }

    handleCancelClose = event => {
        this.setState({
            submitSuccess: false,
            submitError: false,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            reservationTime: "",
            showModal: false
        })
    }

    handleNameOnChange= event =>
        this.setState({ [event.target.id]: event.target.value })

    async handleDateOnChange(event) {
        console.info(event)
        event.preventDefault();
        let dateValue = document.getElementById(event.target.id).value
        this.setState({date: dateValue})
        let response = await this.reservationService.findAvailableTimes(dateValue, this.state.partySize);
        console.info(response)
        this.setState(response)
    }

    handleTimeChange = event => {
        this.setState({reservationTime: event.target.value})
    }

    handlePartySizeChange = event => {
        let availableTimeObject = this.state.availabilityList.find(element => String(element.partySize) === event.target.value)
        let availabilityTimes = [];
        console.log(availableTimeObject);
        if (!!availableTimeObject && availableTimeObject.availableTimes.length !== 0) {
            availabilityTimes = availableTimeObject.availableTimes;
        }
        this.setState({ 
            partySize: event.target.value,
            availableTimes: availabilityTimes
         })
    }

    handlePhoneNumberChange = event => {
        let value = event.target.value;
        value = value.replace(/[^0-9]+/g, '');
        if (value[0] === "1") {value = value.slice(1)}
        if (value.length <= 3) {
                value = `+1 (${value})`
                this.setState({phoneNumber: value})
        } else if (value.length > 3 && value.length < 7) {
            value = `+1 (${value.slice(0,3)}) ${value.slice(3, value.length + 1)}`
            this.setState({phoneNumber: value})
        } else {
            value = `+1 (${value.slice(0,3)}) ${value.slice(3, 6)}-${value.slice(6, value.length + 1)}`
            if (value.length > 17) {value = value.slice(0,17)}
            this.setState({phoneNumber: value})
        }
    };

    render() {
        return (
            <section>
                <div className="container" style={{maxWidth: '225px'}}>
                <h4 className="text-center">Reservations</h4>
                <br />
                <div className="input-group mb-3">
                        <input type="date" id="dateHomePage" className="form-control" onChange={this.handleDateOnChange}
                            value={ this.state.date } />
                </div>
                <div className="input-group mb-3">
                    <Form.Select id="partySizeHomePage" onChange={this.handlePartySizeChange} value={this.state.partySize}>
                        <option defaultValue>{this.state.availableSizes.length !== 0 ? "Party Size ..." : "Party Size ..."}</option>
                        { this.state.availableSizes.map((size, index) => {
                            return <option key={index} value={size}>{size}</option>
                        })}
                    </Form.Select>
                </div>
                <div className="input-group mb-3">
                    <Form.Select id="reservationTimeHomePage" onChange={this.handleTimeChange} value={this.state.reservationTime}>
                        <option defaultValue>{this.state.availableTimes.length !== 0 ? "Select Time ..." : "Select Time ..."}</option>
                        {this.state.availableTimes.map((time, index) => {
                            return <option key={index} value={time}>{time}</option>
                        })}
                    </Form.Select>
                </div>

                <Button variant="danger" className="w-100" onClick={this.handleShowModal}>
                    <i className="fas fa-chair" style={{marginRight: "12px"}} /><strong>Find Table</strong>
                </Button>

                <Modal show={this.state.showModal} onHide={this.handleCancelClose} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Make a reservation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div className="input-group mb-3">
                                <input type="text" id="firstName" className="form-control" onChange={this.handleNameOnChange}
                                       aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                       placeholder="First Name" value={this.state.firstName} required/>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" id="lastName" className="form-control" onChange={this.handleNameOnChange}
                                       aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                       placeholder="Last Name" value={this.state.lastName} required/>
                            </div>
                            <div className="input-group mb-3">
                                <input type="date" id="date" className="form-control" onChange={this.handleDateOnChange}
                                       placeholder="Select Date" value={this.state.date} />
                            </div>
                            <div className="input-group mb-3">
                                <Form.Select className="custom-select" id="partySize" onChange={this.handlePartySizeChange} value={this.state.partySize}>
                                    <option defaultValue>{this.state.availableSizes.length !== 0 ? "Party Size ..." : "No availabilty on selected date"}</option>
                                    { this.state.availableSizes.map((size, index) => {
                                        return <option key={index} value={size}>{size}</option>
                                    })}
                                </Form.Select>
                            </div>
                            <div className="input-group mb-3">
                                <Form.Select className="custom-select" id="reservationTime" onChange={this.handleTimeChange} value={this.state.reservationTime}>
                                    <option defaultValue>{this.state.availableTimes.length !== 0 ? "Select Time ..." : "No availabilty on selected date"}</option>
                                    {this.state.availableTimes.map((time, index) => {
                                        return <option key={index} value={time}>{time}</option>
                                    })}
                                </Form.Select>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" inputmode="decimal" id="phoneNumber" className="form-control" aria-label="Small"
                                       aria-describedby="inputGroup-sizing-sm" placeholder="Phone Number"
                                       onChange={this.handlePhoneNumberChange} value={this.state.phoneNumber} required/>
                            </div>
                            { this.state.submitSuccess ?
                                <p className="h5 text-center" style={{color: "green"}}>Thank you! Your table has been successfully reserved!</p>
                                : null}
                            {this.state.submitError ?
                                <p className="h6 text-center" style={{color: "red"}}>Oops! Something went wrong! Our bad, please try again, if the problem persists, please call use at (269) 469-4001 to make a reservation</p>
                                : null}
                    </Modal.Body>
                    <Modal.Footer>
                            <Button variant="danger" onClick={this.handleCancelClose}>
                                <i className="fas fa-window-close" style={{marginRight: "12px"}} />{this.state.submitSuccess ? "Close" : "Cancel"}
                            </Button>
                            { this.state.submitSuccess ?
                                null
                                : <Button variant="success" className="btn btn-success" onClick={this.handleOnSubmit}>
                                    <i className="fas fa-chair" style={{marginRight: "12px"}} /><strong>Reserve</strong>
                                </Button>}
                    </Modal.Footer>
                </Modal>
                </div>
            </section>
        )
    }
}

import React from 'react';
// import { Link } from 'react-router';
import dineIn from '../../imgs/services/dine-in.jpg';
import catering from '../../imgs/services/catering.jpg';
import privateEvents from '../../imgs/services/private-events.jpg';
import takeOut from '../../imgs/services/take-out.jpg';

const Services = props => {
    return (
        <div className="container text-center">
            <h1 className = "text-center">SERVICES</h1>
            <div className="row">
                <div className="col">
                    <img className="service-image" src={dineIn} alt="dine in" />
                    <br />
                    <h4>Dine In</h4>
                    <p className="text-muted">Come, relax, and Let us do the cooking!</p>
                    <br/>
                </div>
                <div className="col">
                    <img className="service-image" src={privateEvents} alt="dine in" />
                    <h4>Private Parties</h4>
                    <p className="text-muted" >Business meetings, Rehearsal Dinners, and special ocasions</p>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img className="service-image" src={catering} alt="dine in" />
                    <h4>Catering</h4>
                    <p className="text-muted" >Let us cater your special event</p>
                    <br />
                </div>
                <div className="col">
                    <img className="service-image" src={takeOut} alt="dine in" />
                    <h4>Take Out</h4>
                    <p className="text-muted" >Our Homemade specialties To-Go</p>
                    <br />
                </div>
            </div>
        </div>
    )
};

export default Services;
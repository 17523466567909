import React from "react";
import './menu.css'
import { MenuItem } from "../../models/MenuItem";

export default function Starters(props: any) {

    return (
        <>
            <div className="starters-row">
                <div className="starters-column">
                    {props.items.map((item:MenuItem, index:number) =>
                        index < (props.items.length / 2) ?
                            <div key={index}>
                                <p className="menu-p menu-item starters-menu-item"><b>{item.name}</b></p>
                                <p className="menu-p menu-desc starters-menu-desc">{item.desc}</p>
                                <p className="menu-p menu-price starters-menu-price"><i>${item.price}</i></p>
                            </div>

                            : null
                    )}
                </div>
                <div className="starters-column">
                    {props.items.map((item:MenuItem, index:number) =>
                        index >= (props.items.length / 2) ?
                            <div key={index}>
                                <p className="menu-p menu-item starters-menu-item"><b>{item.name}</b></p>
                                <p className="menu-p menu-desc starters-menu-desc">{item.desc}</p>
                                <p className="menu-p menu-price starters-menu-price"><i>${item.price}</i></p>
                            </div>

                            : null
                    )}
                </div>
            </div>
        </>
    )
}

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';
import NavBar from './components/NavBar';
import Menu from './components/menu/menu';
import './index.css';

import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import {BrowserRouter, Route, Routes} from "react-router-dom";

import rootReducer from './reducers/index';
import Footer from "./components/Footer";
import CateringMenu from "./components/menu/menu-catering";
import Jobs from "./components/jobs/JobApplication";

export const backendAPI = () => "http://100.26.186.217:3000"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store = {store} >
        <BrowserRouter>
            <React.Fragment>
                <NavBar />
                <Routes>
                    <Route exact path='/' element = { <App /> } />
                    <Route exact path='/menu' element = { <Menu /> } />
                    <Route exact path='/catering' element = { <CateringMenu /> } />
                    <Route exact path='/jobs' element = { <Jobs /> } />
                    {/*    <Route exact path='/menu/pizza/:name' component = {Pizza} />*/}
                    {/*    <Route exact path='/menu/:name' component = {CategoryContainer} />*/}
                    {/*<Route exact path='/login' component = {Login} />*/}
                    {/*<Route exact path='/signup' component = {SignUp} />*/}
                    {/*<Route exact path='/checkout' component = {CheckOut} />*/}
                    {/*<Route exact path='/profile' component = {Profile} />*/}
                </Routes>
                <Footer />
            </React.Fragment>
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();

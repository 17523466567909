import React from 'react'
import {MenuItem} from "../../models/MenuItem";

export default function Entrees(props:any) {
    return <>

        <div className="entrees-row">
            <div className="entrees-column">
                {props.items.map((item:MenuItem, index:number) =>
                    index % 2 === 0 ?
                    <div key={index}>
                        <div className="menu-p menu-item entrees-menu-item"><b>{item.name}</b></div>
                        <div className="menu-desc entrees-menu-desc">{item.desc}</div>
                        <div className="menu-price entrees-menu-price"><i>${item.price}</i></div>
                    </div>
                        : null
                )}
            </div>
            <div className="entrees-column">
                {props.items.map((item:MenuItem, index:number) =>
                    index % 2 === 1 ?
                        <div key={index}>
                            <p className="menu-p menu-item entrees-menu-item"><b>{item.name}</b></p>
                            <p className="menu-p menu-desc entrees-menu-desc">{item.desc}</p>
                            <p className="menu-p menu-price entrees-menu-price"><i>${item.price}</i></p>
                        </div>
                        : null
                )}
            </div>
        </div>
    </>
}
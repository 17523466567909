import React from 'react';
import './menu.css'
import {PizzaItem} from "../../models/PizzaItem";

export default function Pizza(props: any) {
    return (
        <>
            <div className="pizza-row-items pizza-full">
                <div className="pizza-column-items menu-item">
                    <b>Choice of Items ...</b>
                </div>
                <div className="pizza-column-items menu-item">
                    {props.pizzaItems.map((item:string, index:number) => index < 6 ? <div key={index}><b>{item}</b></div> : null)}
                </div>
                <div className="pizza-column-items menu-item">
                    {props.pizzaItems.map((item:string, index:number) => index > 5 && index < 12 ? <div key={index}><b>{item}</b></div> : null)}
                </div>
                <div className="pizza-column-items menu-item">
                    {props.pizzaItems.map((item:string, index:number) => index > 11 ? <div key={index}><b>{item}</b></div> : null)}
                </div>
                <div className="pizza-column-items menu-item">
                    <b>Choice of Crust ...</b>
                </div>
                <div className="pizza-column-items menu-item">
                    {props.pizzaCrusts.map((item:string, index:number) => <div key={index}><b>{item}</b></div>)}
                </div>
            </div>

            <div className="pizza-mobile">
                <div className="pizza-items-header menu-item">
                    <b>Choice of Items ...</b>
                </div>
                <div className="pizza-items-vertical-scroll">
                    { props.pizzaItems.map((item:string, index:number) => <div className="pizza-items-header menu-item" key={index}>{item}</div> )}
                </div>
                <div className="pizza-items-header menu-item">
                    <b>Choice of Crust ...</b>
                </div>
                <div>
                    { props.pizzaCrusts.map((item:string, index:number) => <div className="pizza-items-header menu-item" key={index}>{item}</div> )}
                </div>
            </div>

            <br />
            <div className="pizza-row-types">
                <div className="pizza-column-types-header">
                </div>
                <div className="pizza-column-types pizza-size-header">
                    <div><b>Small</b></div>
                    <div><b>10"</b></div>
                </div>
                <div className="pizza-column-types pizza-size-header">
                    <div><b>Medium</b></div>
                    <div><b>12"</b></div>
                </div>
                <div className="pizza-column-types pizza-size-header">
                    <div><b>Large</b></div>
                    <div><b>14"</b></div>
                </div>
            </div>

            {props.pizzaTypes.map((item:PizzaItem, index:number) =>
                <>
                    <div className="pizza-row-types">
                        <div className="pizza-column-types-header menu-item">
                            <b>{item.name}</b>
                        </div>
                        <div className="pizza-column-types pizza-price">
                            ${item.smallPrice}
                        </div>
                        <div className="pizza-column-types pizza-price">
                            ${item.mediumPrice}
                        </div>
                        <div className="pizza-column-types pizza-price">
                            ${item.largePrice}
                        </div>
                    </div>
                    {item.desc.length > 0 ? <span className="menu-desc">{item.desc}</span> : null}
                </>
            )}
        </>
    )
}
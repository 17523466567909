import React from 'react';
import {MenuItem} from "../../models/MenuItem";
import {MenuOption} from "../../models/MenuOption";

export default function KidsMeals(props:any) {
    return <>
        <div className="kids-row">
            <div className="kids-column">
                {props.items.map((item:MenuItem, index:number) =>
                    index < (props.items.length / 2) ?
                        <div key={index}>
                            <p className="menu-p menu-item kids-menu-item"><b>{item.name}</b></p>
                            { item.desc ? <p className="menu-p"><span className="menu-desc">{item.desc}</span></p> : null }
                            { item.options ? item.options.map((option:MenuOption, index:number) => <p key={index} className="menu-p menu-desc kids-menu-desc">{option.name} ${option.price}</p>) : null}
                        </div>

                        : null
                )}
            </div>
            <div className="kids-column">
                {props.items.map((item:MenuItem, index:number) =>
                    index >= (props.items.length / 2) ?
                        <div key={index}>
                            <p className="menu-p menu-item kids-menu-item"><b>{item.name}</b></p>
                            { item.desc ? <p className="menu-p"><span className="menu-desc">{item.desc}</span></p> : null }
                            <p className="menu-p menu-desc kids-menu-desc"><i className="menu-price">${item.price}</i></p>
                        </div>

                        : null
                )}
            </div>
        </div>
    </>
}
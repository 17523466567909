import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import homePage from '../../imgs/carousel/home-page.jpeg';
import bakedBread from '../../imgs/carousel/baked-bread.jpg';
import whiteFish from '../../imgs/carousel/white-fish.jpg';

import homePageMobile from '../../imgs/carousel/home-page_mobile.jpeg';
import bakedBreadMobile from '../../imgs/carousel/baked-bread-mobile.jpg';
import whiteFishMobile from '../../imgs/carousel/white-fish-mobile.jpg';

const HomePageCarousel = () => {
    return <Carousel fade>
                <Carousel.Item>
                    <img
                        className="d-none d-sm-block w-100"
                        src={ homePage }
                        alt="Second slide"
                    />
                    <img
                        className="d-block d-sm-none d-sm-none w-100"
                        src={ homePageMobile }
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-none d-sm-block w-100"
                        src={ bakedBread }
                        alt="Third slide"
                    />
                    <img
                        className="d-block d-sm-none w-100"
                        src={ bakedBreadMobile }
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-none d-sm-block w-100"
                        src={ whiteFish }
                        alt="Fourth slide"
                    />
                    <img
                        className="d-block d-sm-none w-100"
                        src={ whiteFishMobile }
                        alt="Fourth slide"
                    />
                </Carousel.Item>
            </Carousel>
}

export default HomePageCarousel;

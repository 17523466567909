import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {Col, Container, FormControl, Row} from "react-bootstrap";

export default class Jobs extends Component {

    POSITIONS = [
        'Server',
        'Bartender',
        'Line Cook',
        'Dishwasher',
        'Host/Hostess',
        'Busser/Server Assistant'
    ]

    handleSubmit = (e: any) => {
        e.preventDefault();
    };

    render() {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col>
                        <h3 className={"text-center"}>Page coming soon!</h3>
                    </Col>
                </Row>
            {/*<h3>Job Application</h3>*/}
            {/*<Form onSubmit={this.handleSubmit}>*/}
            {/*    <h4>Application Information</h4>*/}
            {/*    <Form.Group className="mb-3" controlId={"firstName"}>*/}
            {/*        <Form.Label>First Name</Form.Label>*/}
            {/*        <Form.Control type={"text"} placeholder={"First Name"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group className="mb-3" controlId={"middleName"}>*/}
            {/*        <Form.Label>Middle Name</Form.Label>*/}
            {/*        <Form.Control type={"text"} placeholder={"Middle Name"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group className="mb-3" controlId={"lastName"}>*/}
            {/*        <Form.Label>Last Name</Form.Label>*/}
            {/*        <Form.Control type={"text"} placeholder={"Last Name"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Date of Birth</Form.Label>*/}
            {/*        <FormControl type={"date"}></FormControl>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Mailing Address</Form.Label>*/}
            {/*        <Form.Control type={"text"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Email Address</Form.Label>*/}
            {/*        <Form.Control type={"eamil"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Phone Number</Form.Label>*/}
            {/*        <Form.Control type={"tel"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Are you 18 or older?</Form.Label>*/}
            {/*        <Form.Check type={"radio"} label={"Yes"}></Form.Check>*/}
            {/*        <Form.Check type={"radio"} label={"No"}></Form.Check>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Are you a US Citizen?</Form.Label>*/}
            {/*        <Form.Check type={"radio"} label={"Yes"}></Form.Check>*/}
            {/*        <Form.Check type={"radio"} label={"No"}></Form.Check>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Military Service?</Form.Label>*/}
            {/*        <Form.Check type={"radio"} label={"Yes"}></Form.Check>*/}
            {/*        <Form.Check type={"radio"} label={"No"}></Form.Check>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Are you a veteran?</Form.Label>*/}
            {/*        <Form.Check type={"radio"} label={"Yes"}></Form.Check>*/}
            {/*        <Form.Check type={"radio"} label={"No"}></Form.Check>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Are you a convicted felon?</Form.Label>*/}
            {/*        <Form.Check type={"radio"} label={"Yes"}></Form.Check>*/}
            {/*        <Form.Check type={"radio"} label={"No"}></Form.Check>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>If yes, please explain</Form.Label>*/}
            {/*        <Form.Control type={"text"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <h4>Position</h4>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Position</Form.Label>*/}
            {/*        <Form.Select>*/}
            {/*            { this.POSITIONS.map( (position) => (*/}
            {/*                <option>{position}</option>*/}
            {/*                )*/}

            {/*            )}*/}
            {/*        </Form.Select>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Where did you find us?</Form.Label>*/}
            {/*        <Form.Control type={"text"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Check type={"radio"} label={"Full Time"}></Form.Check>*/}
            {/*        <Form.Check type={"radio"} label={"Part Time"}></Form.Check>*/}
            {/*    </Form.Group>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>Available Start Date</Form.Label>*/}
            {/*        <Form.Control type={"date"}></Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    <h4>Education</h4>*/}
            {/*    <Form.Group></Form.Group>*/}
            {/*    <h4>References</h4>*/}
            {/*    <Form.Group></Form.Group>*/}
            {/*    <h4>Employment History</h4>*/}
            {/*    <Form.Group></Form.Group>*/}
            {/*    <Form.Group></Form.Group>*/}
            {/*</Form>*/}
            </Container>
        );
    }
}
import React from 'react'
import {MenuItem} from "../../models/MenuItem";

export default function Pasta(props: any) {
    return <>
        <div className="pasta-row">
            <div className="pasta-column">
                <div className="menu-item pasta-menu-item"><b>Choice of Pasta ...</b></div>
                {props.pastaTypes.map((item:MenuItem, index:number) => <div className="pasta-option">{item.name}{ item.price !== 0 ? <i> ${item.price}</i> : null}</div>)}
            </div>
            <div className="pasta-column">
                <div className="menu-item pasta-menu-item"><b>Choice of Sauce ...</b></div>
                {props.pastaSauces.map((item:MenuItem, index:number) => <div className="pasta-option">{item.name}{ item.price !== 0 ? <i> add ${item.price}</i> : null}</div>)}
            </div>
        </div>
    </>
}
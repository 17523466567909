import constants from "../Constants";

export class ReservationService {
    HEADER = {
        "Accept" : "application/json",
        "Content-Type" : "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
    }

    async findAvailableTimes(date:Date, partySize:string) {
        return await fetch(constants.getAvailableDatesUrl + date, {headers: this.HEADER})
            .then(response => response.json())
            .then(response => {
                if (response.length > 0) {
                    // Response looks like this [{date: null, partySize: 1, availableTimes: ["4:00", "4.15", ...]}, ...]
                    let availablePartySizes:string[] = [];

                    response.forEach((element:any) => {
                        if (element.availableTimes.length !== 0) {
                            availablePartySizes.push(element.partySize)
                        }
                    })

                    let availableTimes = [];

                    if (partySize) {
                        let availableTimeObject = response.find((element:any) => String(element.partySize) === partySize)
                        if (!!availableTimeObject && availableTimeObject.availableTimes.length !== 0) {
                            availableTimes = availableTimeObject.availableTimes;
                        }
                    }
                    return {
                        availabilityList: response,
                        availableSizes: availablePartySizes,
                        availableTimes: availableTimes
                    }
                } else {
                    return {
                        availabilityList: [],
                        availableTimes: []
                    }
                }
            })
            .catch(error => console.error("[ERROR] Fetch availabily error", error))
    }

    async postReservation(postBody:any) {
        return await fetch(constants.postReservationsUrl, { method: "post", headers: this.HEADER, body: JSON.stringify(postBody)
        })
            .then(res => res.json())
            .then(content => {
                    if (content.id) {
                        return true
                    } else {
                        return false
                    }
                }
            )
            .catch(err => {
                console.error(err);
                return false
            })
    }
 }

import { backendAPI } from "../index";
import { uploadFile} from 'react-s3';
// deleteFile


// API URLS
    const loginURL = () => `${backendAPI()}/api/v1/login`;
    const profileURL = () => `${backendAPI()}/api/v1/profile`;
    const createUserURL = () => `${backendAPI()}/api/v1/users`;

// Headers
    const headers = (data, type) => {
        return {
            method: type, 
            headers: {"Content-Type": "application/json", Accept:"application/json"},
            body: JSON.stringify(data)
            }
    }

// Function
    
    export function checkForToken() {
        return (dispatch) => {
                if (localStorage.token) {
                dispatch({type: "VALIDATING_TOKEN"});
                dispatch(fetchProfile(localStorage.token));
            } else {
                dispatch({type: "NO_TOKEN"})
            }
        }
    };

    export function logOut() {
        return (dispatch) => {
            if (localStorage.token) {
                localStorage.removeItem("token");
                dispatch({type: "LOG_OUT"});
            } else {
                dispatch({type: "NO_TOKEN"})
            }
            dispatch({type: "RESET_USER_ORDERS"})
        }
    }


// Fetch calls


    export function fetchLogin(cred) {
        return (dispatch) => {
            dispatch({type: "LOGGING_IN"});
            return fetch(loginURL(), headers({user: cred}, "POST"))
                    .then(res => res.json())
                    .then(response => dispatch({type: "LOGIN_RESPONSE", payload: response}))
                    .catch(error => console.warn("actions/login/fetchLogin", error))
        }
    };

    function fetchProfile(token) {
        return (dispatch) => { 
            fetch(profileURL(), {headers: {Authorization: `Bearer ${token}`}})
            .then(res => res.json())
            .then(response => dispatch({type: "PROFILE_RESPONSE", payload: response}))
            .catch(error => dispatch({type: "TOKEN_RESET", payload: error}))
        }
    }

    export function createUser(userData) {
        return (dispatch) => {
            dispatch({type: "CREATING_USER"});
            return fetch(createUserURL(), headers(userData, "POST"))
                    .then(res => res.json())
                    .then(response => dispatch({type: "CREATE_USER_RESPONSE", payload: response}))
                    .catch(error => console.warn("actions/login/createUser", error))
        }
    }

    export function updateUser(userData) {
        return (dispatch) => {
            dispatch({type: "UPDATING_USER"})
            // return fetch()
        }
    }

    // I need to delete a photo whenever there is an upload, the previous file.
    export function updatePhoto(file, username) {
        let token = localStorage.getItem("token");
        // Validate Image
        // file.name = `${username}${file.name.split('.')[1]}`;
        return (dispatch) => {
            fetch(`${backendAPI()}/api/v1/api`, {headers: {Authorization: `Bearer ${token}`, api: true}})
            .then(res => res.json())
            .then(json => {
                const config = {
                    bucketName: 'capozios',
                    // dirName: 'capozios', /* optional */
                    region: 'us-east-2',
                    accessKeyId: json.aws1,
                    secretAccessKey: json.aws2,
                }
                dispatch({type: "SENDING_PHOTO"})
                uploadFile(file, config)
                .then(data => {debugger})
            }).catch(error => console.log(error, "AWS fetch error"))
        }
    }
import { MenuItem } from "../../models/MenuItem";
import { PizzaItem } from "../../models/PizzaItem";


const STARTERS_MAP:MenuItem[] = [
    { name: "Italian Julian Salad", desc: "Capicola, salami, cheese, red onion, lettuce, tomato, egg, radish, pepperoncini, and black olives.", price: 14 },
    { name: "Garlic Bread", desc: "", price: 6.5 },
    { name: "Garlic Bread with Cheese", desc: "", price: 8.5 },
    { name: "Homemade Minestrone Soup", desc: "", price: 6 },
    { name: "Capozio's Tomato Bread", desc: "", price: 8.5 },
    { name: "Fried Calamari", desc: "", price: 13.5 },
    { name: "Fried Mushrooms", desc: "Served with homemade marinera Sauce", price: 11 },
    { name: "Fried Mozzarella", desc: "Served with homemade marinera Sauce", price: 11 },
    { name: "Fried Zucchini", desc: "Served with homemade marinera Sauce", price: 11 },
    { name: "Meatball or Link Sausage", desc: "", price: 3.5 },
    { name: "House Salad", desc: "Carrots, tomatoes, onions, black olives, pepperoncini, served with homemade bread", price: 6.5 },
]

const PIZZA_ITEMS:string[] = [
    "Sausage", "Pepperoni", "Meatball", "Sliced Ham", "Sliced Beef", "Bacon", "Green Peppers", "Onion", "Black Olives", "Green Olives", "Mushrooms", "Anchovies", "Pineapple", "Spinach", "Artichoke Hearts", "Tomato"
]

const PIZZA_CRUSTS: string[] = [
    "Thin", "Regular", "Thick", "Sicilian Deep Dish"
]

const PIZZA_TYPES_MAP:PizzaItem[] = [
    { name: "Cheese", desc: "", smallPrice: 12, mediumPrice: 16, largePrice: 18 },
    { name: "1 Item", desc: "", smallPrice: 13, mediumPrice: 17, largePrice: 19 },
    { name: "2 Item", desc: "", smallPrice: 14, mediumPrice: 18, largePrice: 20 },
    { name: "Taco Pizza", desc: "Taco sauce, taco meat, cheese, lettuce, tomato and onions. Served with sour cream and salsa.", smallPrice: 16, mediumPrice: 20, largePrice: 24 },
    { name: "Veggie Pizza", desc: "Tomato, mushrooms, onions, and green peppers", smallPrice: 16, mediumPrice: 20, largePrice: 22 },
    { name: "Capozio's Combo", desc: "Sausage, mushrooms, green peppers, onions – Anchovies on request", smallPrice: 16, mediumPrice: 20, largePrice: 22 },
    { name: "Double / Additional item", desc: "", smallPrice: 1, mediumPrice: 2, largePrice: 2.20 },
    { name: "Sicilian Deep Dish or Thick Crust", desc: "Please allow an extra 10 Minutes for Thick or Sicilian Deep Dish", smallPrice: 1.50, mediumPrice: 2.00, largePrice: 2.50 },
]

const PASTA_TYPES: MenuItem[] = [
    {name: "Spaghetti", desc: "", price: 14},
    {name: "Linguine", desc: "", price: 14},
    {name: "Mostaccioli", desc: "", price: 14},
    {name: "Angel Hair", desc: "", price: 15},
    {name: "Ravioli or Tortellini", desc: "Your choice of meat or cheese filled", price: 17},
]

const PASTA_SAUCES: MenuItem[] = [
    {name: "Meat sauce or Marinara", desc: "", price: 0},
    {name: "Add meatballs or link sausage", desc: "", price: 4},
    {name: "Butter and Garlic sauce", desc: "", price: 4},
    {name: "Alfredo sauce", desc: "", price: 5},
]

const ENTREES: MenuItem[] = [
    {name: "Manicotti", desc: "Homemade pasta cylinder stuffed with seasoned ricotta cheese filling with your choice of meat sauce or marinara", price: 19},
    {name: "Cannelloni", desc: "Homemade pasta cylinder stuffed with seasoned meat and spinach filling with your choice of meat sauce or marinara", price: 19},
    {name: "Lasagna", desc: "Seasoned meat layered with ricotta cheese and our blended cheese all covered with homemade meat sauce", price: 19},
    {name: "Spinach Lasagna", desc: "Seasoned spinach layered with ricotta cheese and our blended cheese all covered with homemade marinara sauce", price: 19},
    {name: "Pollo Parmesan", desc: "Boneless, skinless chicken breasts baked in marinara or meat sauce served with a side of spaghetti", price: 20},
    {name: "Egg Plant Parmesan", desc: "A homemade Italian tradition baked in marinara or meat sauce served with a side of spaghetti", price: 18},
    {name: "Tortellini Prosciutto Alfredo", desc: "Tortellini topped with prosciutto & mushroom alfredo sauce. This stuff is unbelievable!", price: 23},
    {name: "Chicken Alfredo", desc: "Take our wonderful alfredo sauce and tender chicken breast, pour on top of linguine and how can you go wrong!", price: 23},
    {name: "White Fish", desc: "Lake Superior white fish cooked in butter, lemon, and herbs served with salad and tomato bread ", price: 24},
]

const KIDS_MEALS: MenuItem[] = [
    {name: "Mostaccioli", desc: "", price: 0, options: [{ name: "Meat Sauce or Marinara", price: 7}, { name: "Add meatball or sausage", price: 8}, { name: "Butter", price: 8}]},
    {name: "Chicken Strips with French Fries", desc: "", price: 9}
]

const SANDWICHES: MenuItem[] = [
    {name: `13" Capozio's Sub`, desc: "Italian capicola, genoa salami, cheese, lettuce, tomato, onion, pepperoncini, and vinegar & oil dressing", price: 14},
    {name: `7" Capozio's Sub`, desc: "Italian capicola, genoa salami, cheese, lettuce, tomato, onion, pepperoncini, and vinegar & oil dressing", price: 12},
    {name: "Meatball", desc: "Old fashioned hand-rolled meatballs smothered in meat sauce served with or without green peppers ", price: 12},
    {name: "Sausage", desc: "Homemade link sausage smothered in meat sauce served with or without green peppers ", price: 12},
    {name: "Beef", desc: "Top round sliced beef and our homemade Italian beef juice, with choice of green peppers or pepperoncini", price: 13},
]

export const MENU_MAP = new Map<string, object>()
MENU_MAP.set("starters", STARTERS_MAP)
MENU_MAP.set("pizzaItems", PIZZA_ITEMS)
MENU_MAP.set("pizzaCrusts", PIZZA_CRUSTS)
MENU_MAP.set("pizzaTypes", PIZZA_TYPES_MAP)
MENU_MAP.set("pastaTypes", PASTA_TYPES)
MENU_MAP.set("pastaSauces", PASTA_SAUCES)
MENU_MAP.set("entrees", ENTREES)
MENU_MAP.set("kidsMeals", KIDS_MEALS)
MENU_MAP.set("sandwiches", SANDWICHES)

import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";

// Form is created, need to setup ActionMailer on backend
// Will phase from EmailJS to ActionMailer when backend is setup
// Currently using EmailJS for sending emails

const emailJSTemplete = () => "capozios_website";
const emailJSUserID = () => "user_rxsd2HkQ1tjw5vWajNY2V";


export default class Contact extends Component {

    state = {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        need: ""
    }

    handleFormChange = event => {
        this.setState({[event.target.name]: event.target.value})
    }

    handleSubmit = event => {
        event.preventDefault();
          this.sendFeedback()
    }

    sendFeedback = () => {
        emailjs.send(
            'mailgun',
            emailJSTemplete(),
            this.state,
            emailJSUserID()
            )
            .then(res => {
                this.formReset();
                window.alert("Your email has been sent")
            })
            .catch(err => {
                console.error('Failed to send feedback. Error: ', err)
                window.alert("Error sending email")
            })

    }

    formReset = () => {
        this.setState({firstName: "", lastName: "", message: "", email: ""})
    }

    render () {
        return (
            <div className="container" id="contact">
            <h1 className="text-center" >CONTACT</h1>
            <p className="text-muted"><strong>*</strong> All fields required</p>
            <form id="contact-form" onSubmit={ this.handleSubmit }>
                <div className="controls">
                    <div className="row">
                        <div className="col-md-6 mt-2">
                            <div className="form-group">
                                <input
                                type="text" name="firstName"
                                className="form-control"
                                placeholder="First Name"
                                required
                                value={this.state.firstName}
                                onChange={this.handleFormChange}
                                data-error="Firstname is required." />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mt-2">
                                <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                placeholder="Last Name"
                                required
                                onChange={this.handleFormChange}
                                value={this.state.lastName}
                                data-error="Lastname is required." />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mt-2">
                                <input
                                type="email" name="email"
                                className="form-control"
                                placeholder="E-mail Address"
                                required
                                value={this.state.email}
                                onChange={this.handleFormChange}
                                data-error="Valid email is required." />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mt-2">
                                <Form.Select name="need" onChange={this.handleFormChange} value={this.state.need} required>
                                    <option value="">Please specify your need</option>
                                    <option value="catering">Catering</option>
                                    <option value="reservations">Reservations</option>
                                    <option value="take-out">Take-Out</option>
                                    <option value="private-events">Private Events</option>
                                    <option value="job-application">Job Application</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group mt-2">
                                <textarea
                                name="message"
                                className="form-control"
                                placeholder="Message"
                                rows="4"
                                required
                                value={this.state.message}
                                onChange={this.handleFormChange}
                                data-error="Please, leave us a message."></textarea>
                                <div className="help-block with-errors"></div>
                            </div>
                            <br />
                            <div className="text-center">
                                <Button type="submit" className="btn btn-success btn-send"><i className="fas fa-paper-plane" style={{marginRight: "6px"}}></i> Send Message</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
        )
    }
};
import React from 'react';
import Button from "react-bootstrap/Button";

const PhoneNumber = ( props:any ) =>
    <div className="text-center">
        <Button variant="success">
            <a style={{textDecoration: "none", color: "white"}} href="tel:2694694001">
                <i className="fas fa-phone" style={{marginRight: "6px"}} />(269) 469 - 4001
            </a>
        </Button>
    </div>


export default PhoneNumber;
import React from 'react';
import './Footer.css'
import facebook from '../imgs/social-media/facebook.png';
import instagram from '../imgs/social-media/instagram.png';
import {Col, Container, Row} from "react-bootstrap";

const Footer = (props:any) => {
    return <Container fluid>
            <br />
            <Row>
                <Col xs>
                    <div className="footer-text">
                        Copyright © Capozio's 2022
                    </div>
                </Col>
                <Col xs>
                    <div className="social-media-image-container">
                        <a className="" href="https://www.facebook.com/CapoziosOfHarbert">
                            <img className = "social-media-image" src={ facebook } alt="Facebook Social Media"/>
                        </a>
                        <a href="https://www.instagram.com/capoziosofharbert/">
                            <img className = "social-media-image social-media-image-margin" src={ instagram } alt="Instagram Social Media"/>
                        </a>
                    </div>
                </Col>
                <Col xs>
                    <div className="footer-text">
                        13982 Red Arrow Hwy Harbert, MI 49115
                    </div>
                </Col>
            </Row>
            <br/>
        </Container>
};

export default Footer;